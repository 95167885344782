<template>
  <b-sidebar
    id="new-edit-sidebar"
    :visible="isNewEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @reset.prevent="resetForm"
      >
        <b-form-group
          label="Módulo Padre"
          label-for="moduleParent"
        >
          <v-select
            id="moduleParent"
            v-model="moduleParentSel"
            label="name"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="modulesParent"
            cle
          />
        </b-form-group>

        <!-- Nombre -->
        <b-form-group
          label="Nombre"
          label-for="nombre"
        >
          <b-form-input
            id="nombre"
            v-model="formData.name"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>
        <b-form-group
          label="Path"
          label-for="path"
        >
          <b-form-input
            id="path"
            v-model="formData.path"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>
        <b-form-group
          label="Icono"
          label-for="icon"
        >
          <b-form-input
            id="icon"
            v-model="formData.icon"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>
        <b-form-group
          label="Orden"
          label-for="order"
        >
          <b-form-input
            id="order"
            v-model="formData.orderIndex"
            autofocus
            trim
            placeholder=""
            type="number"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isNewEditSidebarActive',
    event: 'update:is-new-edit-sidebar-active',
  },
  props: {
    isNewEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const formData = ref({})
    const modulesParent = ref([])
    const moduleParentSel = ref({})

    const resetForm = () => {
      moduleParentSel.value = {}
      formData.value = {}
    }

    const loadParents = async () => {
      const defaultModule = { idModule: 0, name: 'Ninguno' }

      const response = await store.dispatch('security/MODULE_PARENT', { idModuleParent: 0 })
      modulesParent.value = response ? [defaultModule, ...response] : [defaultModule]
    }

    const saveForm = () => {
      if (moduleParentSel.value === null || moduleParentSel.value === '') {
        emit('error-data', { response: { data: { message: 'Debe seleccionar módulo padre' } } })
        return
      }
      if (formData.value.name == null || formData.value.name === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar nombre del módulo' } } })
        return
      }
      if (formData.value.path == null || formData.value.path === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar path del módulo' } } })
        return
      }
      if (formData.value.orderIndex == null || formData.value.orderIndex === '') {
        emit('error-data', { response: { data: { message: 'Debe ingresar orden del módulo' } } })
        return
      }

      formData.value.idModuleParent = moduleParentSel.value.idModule
      let service = 'security/MODULE_CREATE'
      if (props.formType === 'edit') {
        service = 'security/MODULE_UPDATE'
      }
      store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-new-edit-sidebar-active', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-new-edit-sidebar-active', false)
        })
    }

    const toggleSidebar = async val => {
      emit('update:is-new-edit-sidebar-active', val)
      resetForm()
      await loadParents()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Módulo'
      }
      if (props.formType === 'edit') {
        formData.value = props.dataEdit
        moduleParentSel.value = modulesParent.value.find(m => m.idModule === props.dataEdit.idModuleParent)
        titleForm.value = 'Editar Módulo'
      }
    }

    return {
      formData,
      saveForm,
      resetForm,
      toggleSidebar,
      titleForm,
      modulesParent,
      moduleParentSel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
